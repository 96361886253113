import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import { useMemo } from 'react';
import Check from '../../components/icons/Check';
import Button, { ButtonLinkType } from '../Button';
import Text from '../Text';
import colors from '../../theme/colors';

export interface IHero {
  title?: string;
  description?: string;
  list: string[];
  btnTitle?: string;
  btnHref?: string;
  bgImage?: any;
}

const HeaderHero = ({
  list,
  title,
  btnTitle,
  btnHref,
  bgImage,
  description,
}: IHero) => {
  const [isLoaded, setLoaded] = React.useState(false);

  const Content = useMemo(
    () => (
      <section className="header__hero">
        <div className="header__hero__wrapper">
          <Text
            type="h1"
            weight="medium"
            color="light"
            className="header__hero__title--main h2"
          >
            {title}
          </Text>

          <Text
            type="p"
            color="light"
            className="header__hero__description--main p-updated"
          >
            {description}
          </Text>
          <ul className="header__hero__list">
            {list.map((l, i) => (
              <li key={i} className="header__hero__list-item">
                <Check color={colors.main.accentColor.SECONDARY} />
                <Text
                  type="p"
                  color="light"
                  className="header__hero__list-item__text p-updated"
                >
                  {l}
                </Text>
              </li>
            ))}
          </ul>
          <Button
            size="big"
            href={btnHref}
            rounded
            color="secondary"
            className={'header__hero__action__btn'}
            linkType={ButtonLinkType.ANCHOR}
          >
            {btnTitle}
          </Button>
        </div>
        <div className="header__hero__details">
          <div className="header__hero__flag">
            <Text type="p" color="light">
              5/5 Stars
            </Text>
            <Link
              to="https://clutch.co/profile/stormotion"
              rel="nofollow noopener"
            >
              <StaticImage
                src="../../images/clutch.png"
                alt="highly recommended on clutch"
                placeholder="blurred"
                className={'header__hero__image-logos'}
              />
            </Link>
          </div>
          <div className="header__hero__flag">
            <Text type="p" color="light">
              Top Rated
            </Text>
            <StaticImage
              src="../../images/upwork.png"
              placeholder="blurred"
              alt="best upwork company"
              className={'header__hero__image-logos'}
            />
          </div>
        </div>
      </section>
    ),
    [title, description, list, btnHref, btnTitle]
  );

  React.useEffect(() => {
    setTimeout(() => setLoaded(true), 3200);
  }, []);

  return (
    <div
      className={`${
        !isLoaded && 'header__hero__image__bg--empty'
      } header__hero-legacy__image__bg`}
    >
      {isLoaded ? (
        <BackgroundImage
          Tag="div"
          className="header__hero__image__bg__wrapper"
          {...convertToBgImage(getImage(bgImage))}
        >
          {Content}
        </BackgroundImage>
      ) : (
        Content
      )}
    </div>
  );
};

export default HeaderHero;
