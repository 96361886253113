import React, { FC, memo } from 'react';
import colors from '../../theme/colors';

interface CheckProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const Index: FC<CheckProps> = ({ color = colors.main.accentColor.PRIMARY }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3867 22.0664C16.7285 22.0664 21.123 17.6719 21.123 12.3301C21.123 6.98828 16.7285 2.60352 11.3867 2.60352C6.04492 2.60352 1.65039 6.98828 1.65039 12.3301C1.65039 17.6719 6.04492 22.0664 11.3867 22.0664ZM10.3613 16.959C9.96094 16.959 9.63867 16.7734 9.3457 16.4316L7.16797 13.8242C6.96289 13.5703 6.875 13.3359 6.875 13.0723C6.875 12.5156 7.32422 12.0664 7.88086 12.0664C8.21289 12.0664 8.45703 12.1934 8.71094 12.4961L10.3418 14.498L13.9648 8.73633C14.209 8.35547 14.502 8.16016 14.8633 8.16016C15.4004 8.16016 15.8984 8.56055 15.8984 9.11719C15.8984 9.35156 15.8008 9.5957 15.6445 9.83008L11.3477 16.4121C11.1035 16.7637 10.7617 16.959 10.3613 16.959Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Index);
